$highlighted-color: var(--wt-color-success);

.highlighted {
  color: $highlighted-color;
}

.ldap {
  width: 313px;
  display: inline-block;
  margin-right: 24px !important;
}

.right {
  text-align: right;
}
