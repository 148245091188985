.global {

  & > svg > g [class=rv-xy-plot__grid-lines__line] {
    &:first-of-type {
      display: none;
    }
  }

  & > svg [class*=rv-xy-plot__axis--horizontal] [class=rv-xy-plot__axis__tick]:last-of-type [class=rv-xy-plot__axis__tick__text] {
    fill: var(--wt-color-dark) !important;
  }
}

.legends {
  display: flex;
  margin-top: 6px;

  & [class*=rv-discrete-color-legend-item] {
    color: var(--wt-color-dark-70) !important;
  }
}

.maxLineLegends svg path {
  stroke-dasharray: 2;
}

.rectLegends svg {
  height: 5px;

  & path {
    stroke-width: 13px;
  }
}

.crosshair {
  top: 0 !important;

  & [class*='rv-crosshair__inner'] {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    background-color: var(--wt-color-grey-light);
    min-width: 160px;
    padding: 4px 12px;
    border-radius: 4px;
    box-shadow: 0 1px 3px var(--wt-color-dark-20);
    display: flex;
    justify-content: space-between;

    & > div:first-of-type {
      display: flex;
      margin-right: 5px;

      & > div:first-of-type {
        text-align: right;
        margin-right: 5px;
      }
    }

    & div {
      font-size: 12px !important;
    }
  }

  & [class*='rv-crosshair__line'] {
    background: var(--wt-color-dark-40);
    height: 140px !important;
  }
}


.smallCrosshair {
  & [class*='rv-crosshair__inner'] {
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 120px;
    background-color: var(--wt-color-grey-light);
    padding: 4px 12px;
    border-radius: 4px;
    box-shadow: 0 1px 3px var(--wt-color-dark-20);
    display: flex;
    justify-content: space-between;

    & > div:first-of-type {
      display: flex;
      margin-right: 5px;

      & > div:first-of-type {
        text-align: right;
        margin-right: 5px;
      }
    }

    & div {
      font-size: 12px !important;
    }
  }

  & [class*='rv-crosshair__line'] {
    opacity: 0;
  }
}
