.select {
  position: relative;
  z-index: 2;
}

.options {
  background: white;
  z-index: 1501;
  position: absolute;
  top: 100%;
  width: calc(100% - 2px);
  border: 1px solid var(--wt-color-dark-20);
  border-top: none;
}

.input {
  & input::placeholder {
    color: var(--wt-color-dark-40) !important;
  }
}
