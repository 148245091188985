.icon {
  color: var(--wt-color-dark-70);
  margin-left: 12px;
}

.emptyIcon {
  display: inline-block;
  width: 28px;
}

.noWrap {
  display: inline-block;
}
