$product-dropdown-multiple-button-color: var(--wt-color-primary-light-theme);
$product-dropdown-multiple-hover-button-color: var(--wt-color-primary-light-theme-80);
$grey-icon-color: var(--wt-color-dark-40);
$menu-dropdown-hover-more-icon-color: var(--wt-color-dark-70);

//Table
.numberCell {
  display: block;
  text-align: right;
}

.menuIconAlign {
  & > div > div > table > tbody > tr > td {
    vertical-align: top;

    &:last-of-type > span {
      height: 24px;
      display: inline-block;
    }
  }
}

.usernameWithoutLicensesLabel {
  cursor: default;
}

//Product-dropdown
.licenseOrProductDropdownButton {
  display: inline-flex;
  color: $product-dropdown-multiple-button-color;
  cursor: pointer;
  transition: color ease-out 150ms;

  &:hover {
    color: $product-dropdown-multiple-hover-button-color;
  }

  & svg {
    margin-left: 5px;
  }
}

.licenseOrProductDropdownButtonUnused {
  @extend .licenseOrProductDropdownButton;
  color: $grey-icon-color;

  &:hover {
    color: $grey-icon-color;
  }
}

.dropdownWrapperXxs {
  min-width: 148px;
}

.dropdownWrapperXs {
  min-width: 280px;
}

//Menu
.moreIcon {
  display: block;
  color: $grey-icon-color;
  cursor: pointer;
  transition: color ease-out 150ms;

  &:hover {
    color: $menu-dropdown-hover-more-icon-color;
  }
}

.revokeIconAlign {
  align-items: center;
}

.emptyMenu {
  width: 24px;
}

.unusedIcon {
  color: $grey-icon-color;

  &:hover {
    color: $menu-dropdown-hover-more-icon-color;
  }
}

.pendingRevokeIcon {
  color: var(--wt-color-dark-20);

  &:hover {
    color: var(--wt-color-dark-20);
  }
}

.licenseOrProductDropdownButtonPendingChanges {
  @extend .licenseOrProductDropdownButton;
  color: var(--wt-color-dark-20);

  &:hover {
    color: var(--wt-color-dark-20);
  }
}

.pendingChangesColor {
  color: var(--wt-color-dark-20);
}
