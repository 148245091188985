.boldText {
  font-weight: bold;
}

.paleIcon {
  color: var(--wt-color-dark-40)
}

.groupOrUserIcon {
  color: var(--wt-color-dark-70);
  margin-top: 4px
}

.menuIconAlign {
  & > div > div > table > tbody > tr > td {
    vertical-align: middle;

    & [data-test='dropdown-trigger'] {
      display: block;
    }
  }
}

.tableAlign {
  & tbody > tr > td {
    padding: 6px 48px 6px 0;

    &:last-of-type {
      padding: 6px 12px 6px 0;
    }
  }
}

.fixInputWidth {
  & > div:first-of-type > div:first-of-type {
    max-width: 100%;
    flex-basis: 100%;
  }

  & > div:first-of-type > div:last-of-type:not(:first-of-type) {
    margin: 12px auto;
  }
}

.breakName {
  overflow-wrap: anywhere;
}

.popup {
  height: 80vh;

  & > div:first-child {
    border-bottom: 1px solid var(--wt-color-dark-20);
  }

  @media screen and (max-width: 1000px) {
    min-width: 90% !important;
  }
}

.error {
  color: var(--wt-color-error)
}
