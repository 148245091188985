$border-color: var(--wt-color-dark-20);
$enabled-user-color: var(--wt-color-success);
$disabled-user-color: var(--wt-color-error);
$rule-name-color: var(--wt-color-primary-light-theme);
$tab-color: var(--wt-color-dark-40);

.popupHeader {
  height: 80vh;

  & > div:first-child {
    z-index: 3;
    border-bottom: 1px solid $border-color
  }

  & > div:nth-child(2) {
    margin: -2px 0;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--wt-color-dark-40);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--wt-color-dark-70);
    }
  }

  & > div:last-child {
    z-index: 3;
    border-top: 1px solid $border-color
  }
}


.enabledUser {
  color: $enabled-user-color
}

.disabledUser {
  color: $disabled-user-color
}

.ruleName {
  color: $rule-name-color
}

.boldText {
  font-weight: bold;
}

.tab {
  position: relative;

  &:not(:first-of-type) {
    margin-left: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $tab-color;
  }
}

.error {
  color: var(--wt-color-error)
}

.current {
  border-radius: 2px;
  display: inline-block;
  background-color: var(--wt-color-primary-light-theme-20);
  padding: 0 4px;
}

.currentRuleName {
  overflow-wrap: anywhere;
  margin-right: 8px;
}

.disableNameRule {
  text-decoration: line-through;
}

.productSelectPlaceholder {
  & [class*="_wt-select__label"] {
    color: var(--wt-color-dark-40) !important;
  }

  & input {
    color: var(--wt-color-dark-70) !important;
  }
}

.popup {
  @media screen and (max-width: 1000px) {
    min-width: 90% !important;
  }
}
