.alignMenu {
  & tbody > tr > td {
    &:last-of-type {
      vertical-align: top;

      & > span {
        height: 24px;
        display: inline-block;
      }
    }
  }
}


.hideSubHeader {
  & thead > tr:last-child {
    display: none;
  }
}

.table {
  & td {
    border-top: none !important;
  }

  & tbody > tr > td {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.rowBorder {
  & > td {
    border-top: 1px solid var(--wt-color-dark-20) !important;
  }
}

.emptyMenu {
  width: 24px;
}

