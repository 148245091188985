.exportButton {
  & > div:first-child {
    margin-right: 6px
  }

  & > div:last-child {
    margin-left: 0
  }

  & button {
    padding: 2px 0;
  }

  & button:hover, {
    opacity: 0.8;
  }
}

.exportIcon {
  color: var(--wt-color-dark-70)
}
