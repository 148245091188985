@import 'src/scss-global/constants/media';

$menu-active-button-color: var(--wt-color-primary-dark-theme-20);
$menu-hover-button-color: var(--wt-color-primary-dark-theme-20);
$menu-external-link-icon-color: var(--wt-color-white-30);
$sidebar-background-color: black;
$topBar-background-color: var(--wt-color-dark);
$toBar-success-icon-color: var(--wt-color-success);
$topBar-dropdown-icon-color: var(--wt-color-white-60);
$user-text-color: var(--wt-color-white-60);

//Sidebar
.sidebar {
  & a {
    text-decoration: none;
  }

  min-width: 240px;
  background-color: $sidebar-background-color;
  @media screen and (max-width: $sm) {
    flex-basis: 0;
    min-width: 65px !important;
    & span {
      display: none;
    }
  }
}

.logo {
  padding: 16px 16px 0 16px;
}

//Menu
.active {
  & li {
    color: white !important;
    background-color: $menu-active-button-color;

    &:hover {
      background-color: $menu-hover-button-color !important;
    }
  }
}

.externalLinkIcon {
  color: $menu-external-link-icon-color;
}

// Footer
.footer {
  min-width: 240px;
  @media screen and (min-height: 550px) {
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    position: absolute;
  }
  @media screen and (max-width: $sm) {
    flex-basis: 0;
    min-width: 65px !important;
    width: 65px;
    & span {
      display: none;
    }
  }
}

//TopBar
.topBar {
  background-color: $topBar-background-color;

  .topBarRow {
    min-height: 56px;
  }
}


.topBarOffset {
  padding: 4px 0 16px 0;
}

.lastConnectedInfo {
  min-width: 240px;
}

.successIcon {
  color: $toBar-success-icon-color;
}

.dropdownIcon {
  color: $topBar-dropdown-icon-color;
}

//Content
.container {
  overflow-y: auto;
  height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

.layout {
  b {
    font-weight: bold;
  }
}

.largeSizeContainer {
  @media screen and (min-width: $xlg) {
    padding-left: calc((100% / 12 - 16px));
  }
}

.section {
  padding-bottom: 96px;
}

@media screen and (max-width: 1000px) {
  .section {padding-bottom: 180px;}
}

.logOutDropdown {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
}

.logOutDropdown:hover {
  opacity: 0.8;
  transition: opacity 0.13s ease-in;
}

.username {
  margin: 0 12px;
}

.topBarLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 340px; // [1920px - ...]
}

@media screen and (max-width: 1920px) { // [1540px - 1920px]
  .topBarLabel {max-width: 370px;}
}

@media screen and (max-width: 1540px) { // [1276px - 1540px]
  .topBarLabel {max-width: 280px;}
}

@media screen and (max-width: 1276px) { // [1000px - 1276px]
  .topBarLabel {max-width: 170px;}
}

@media screen and (max-width: 1000px) { // [640px - 1000px]
  .topBarLabel {max-width: 290px;}
}

@media screen and (max-width: 640px) { // [480px - 640px]
  .topBarLabel {max-width: 290px;}
}

@media screen and (max-width: 480px) { // [360px - 480px]
  .topBarLabel {max-width: 180px;}
}
