$subtitle-color: var(--wt-color-dark-70);

.subtitle {
  color: $subtitle-color;
}

.boldText {
  font-weight: bold;
}

.domainTitle {
  word-break: break-all;
}

.domainBox {
  min-width: 690px;
  border: 1px solid var(--wt-color-dark-20);
  padding: 12px 24px;
  @media screen and (max-width: 1000px) {
    min-width: 0;
  }
}

.copy {
  padding: 6px 0;
  font-size: 15px;
  display: inline;
  background: white;
  border: none;
  transition: color 0.3s ease-out;

  &:hover {
    color: var(--wt-color-primary-light-theme-80);
  }

  &:focus {
    box-shadow: none;
  }
}


.emptyCell {
  height: 24px;
}

.table {
  & td {
    border-top: none !important;
  }

  & tbody > tr > td {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.rowBorder {
  & > td {
    border-top: 1px solid var(--wt-color-dark-20) !important;
  }
}

.disconnectionDescriptor {
  font-weight: bolder;
}

.disconnectPending {
  color: var(--wt-color-dark-40);
}
