$border-color: var(--wt-color-dark-20);

.popup {
  & > div:first-of-type {
    padding: 15px 30px;
    & > button:last-of-type {
      display: none;
    }
  }
  & > div:nth-of-type(2) {
    border-top: 1px solid $border-color;
  }
  & > div:last-of-type {
    margin: -2px 0;
  }
}

.button {
  color: rgba(39, 40, 44, 0.7);
}

.thresholdSelect {
  max-width: 100px !important;
}

.toggle {
  min-width: 48px;
}

.settingsButton {
  & > svg {
    min-height: 1.4em;
    min-width: 1.4em;
  }
  border-bottom: none !important;
  &:hover > p {
    border-bottom: 1px solid rgb(22, 125, 255);
  }
  & > p {
    border-bottom: 1px solid transparent;
  }
}
