@font-face {
  font-family: "JetBrains Mono";
  src: local("JetBrains Mono Regular"), url('../../../../resources/common/fonts/JetBrainsMono-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

.textArea {
  font-family: "JetBrains Mono", monospace;
  background: none;
  outline: none;
  resize: none;
  flex-grow: 1;
  border: 0;
  margin: 8px 2px;
}

.wrapper {
  border: 1px solid var(--wt-color-dark-40);
  margin: 0 0 1px 0;
}

.wrapperError {
  border: 1px solid var(--wt-color-error);
}

.iconWrapper {
  padding: 8px 24px 12px 24px;
}
