@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(loading) {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@mixin animation($param) {
  -webkit-animation: #{$param};
  animation: #{$param};
}

.callHomeButton {
  & > div:first-child {
    margin-right: 6px
  }

  & > div:last-child {
    margin-left: 0
  }

  & button {
    padding: 2px 0;
  }

  & button:hover, {
    opacity: 0.8;
  }
}

.rotateAnimation {
  & svg {
    @include animation('loading 1.5s infinite linear');
  }
}
