.multiselect {
  border: 1px solid var(--wt-color-dark-20);
  display: flex;
  flex-wrap: wrap;
  cursor: text;
  position: relative;
  z-index: 2;

  & > span {
    margin: 6px;
    height: 36px;
    cursor: default;
  }

  & > label {
    flex: auto;
    max-width: 100%;
    cursor: text;

    & > div > textarea {
      word-break: break-all;
      max-width: 720px;
      padding: 11px 14px !important;
      border: none
    }
  }
}

.oneLine {
  min-height: 48px;
}

.multiLine {
  min-height: 98px;
}

.options {
  position: absolute;
  top: 100%;
  left: -1px;
  width: 100%;
  border: 1px solid var(--wt-color-dark-20);
  background-color: white;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--wt-color-dark-40);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--wt-color-dark-70);
  }

}

.optionsSizeS {
  max-height: 144px;
}

.optionsSizeM {
  max-height: 192px;
}

.placeholder {
  position: absolute;
  z-index: 3;
  cursor: inherit !important;
  margin: 11px 14px !important;
}

.error {
  margin-top: 8px;
  color: var(--wt-color-error);
}

.multiselectError {
  border: 2px solid var(--wt-color-error);
}
