$empty-number-cell-color: var(--wt-color-dark-20);
$full-number-cell-color: var(--wt-color-error);

.numberCell {
  text-align: right;
  display: block;
}

.emptyNumberCell {
  color: $empty-number-cell-color;
}

.fullNumberCell {
  color: $full-number-cell-color;
}

.versionsCell {
  padding-top: 8px;
}

.hideTableBorder {
  & > div > div > table > tbody > tr:not(:first-child) > td {
    border-style: hidden;
  }
}

.firstTableTrOffset {
  & > div > div > table > tbody > tr:first-child > td {
    padding-top: 20px;
  }
}
