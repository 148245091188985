$unruled-users-checkbox-enabled-color: rgba(77, 187, 95, 0.1);
$unruled-users-checkbox-disabled-color: rgba(243, 192, 51, 0.1);

.paleIcon {
  color: var(--wt-color-dark-40)
}

.subtitle {
  color: var(--wt-color-dark-70);
}

.nthAndMore {
  cursor: pointer;
}

.nthAndMoreBlock {
  margin-left: 4px;
  display: inline-block;
}

.ruleTitle {
  display: inline-block;
  color: var(--wt-color-primary-light-theme);
  font-weight: bold;
  cursor: pointer;
  transition: color 200ms ease;

  &:hover {
    color: var(--wt-color-primary-light-theme-80);
  }
}

.detailBlock {
  max-width: 530px;
}

.menuIconAlign {
  & > div > div > table > tbody > tr > td {
    vertical-align: middle;

    & [data-test='dropdown-trigger'] {
      display: block;
    }
  }
}

.tableAlign {
  & tbody > tr > td {
    padding: 12px 48px 12px 0;

    &:last-of-type {
      padding: 12px 12px 12px 0;
    }
  }
}

.error {
  color: var(--wt-color-error)
}


.unruledUsersCheckbox {
  padding: 16px;
  height: 100%;

  & > div > div > span:first-child {
    margin-left: 40px;
  }

  & label {
    padding: 0 !important;
  }
}

.unruledUsersCheckboxEnabled {
  background-color: $unruled-users-checkbox-enabled-color;
}

.unruledUsersCheckboxDisabled {
  background-color: $unruled-users-checkbox-disabled-color;
}

.boldText {
  font-weight: bold;
}

.disableNameRule {
  text-decoration: line-through;
}
