:root {
    --rs-color-primary-light-theme: #167DFF;
    --rs-color-primary-dim-light-theme: #4597FF;
    --rs-color-primary-fog-light-theme: #D0E5FF;
    --rs-color-primary-t-dim-light-theme: rgba(22, 125, 255, 0.8);
    --rs-color-primary-t-fog-light-theme: rgba(22, 125, 255, 0.2);

    --rs-color-primary-dark-theme: #8473FF;
    --rs-color-primary-dim-dark-theme: #6F61D2;
    --rs-color-primary-fog-dark-theme: #2E2B49;
    --rs-color-primary-t-dim-dark-theme: rgba(132, 115, 255, 0.8);
    --rs-color-primary-t-fog-dark-theme: rgba(132, 115, 255, 0.2);
}
