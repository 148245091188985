$active-step-list-background-color: var(--wt-color-primary-light-theme-20);
$active-step-list-color: var(--wt-color-dark);
$step-list-color: var(--wt-color-primary-light-theme);
$copy-icon-color: var(--wt-color-dark-70);
$remove-icon-color: var(--wt-color-error);
$border-color: rgba(39, 40, 44, 0.1);

.header {
  & > div:first-of-type {
    padding: 24px 32px;

    & > button:last-of-type {
      display: none;
    }

    & div:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    & div:first-child > label {
      margin-right: 24px;
    }
  }
}


.removeIcon {
  color: $remove-icon-color;
}

.copyIcon {
  color: $copy-icon-color;
}

.content {
  max-height: 400px;
  border: 1px solid $border-color;
  overflow: hidden;
  margin: 0;

  & > :first-child {
    margin: 0;
  }

  & > :last-child {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    padding: 0 24px 0 24px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--wt-color-dark-40);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--wt-color-dark-70);
    }
  }
}

.list {
  z-index: 1;
  border-right: 1px solid $border-color;

  & span {
    color: $step-list-color;
  }
}

.active {
  & span {
    font-weight: bold;
    color: $active-step-list-color;
  }

  background-color: $active-step-list-background-color;
}

.error {
  color: var(--wt-color-error)
}

.errorLabel {
  margin-left: 8px;
  display: inline-block;
  margin-top: 2px;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: var(--wt-color-error);
}

.stepLabel span {
  display: flex;
  align-items: center;
}

.popup {
  @media screen and (max-width: 1200px) {
    min-width: 90% !important;
  }
  @media screen and (max-width: 760px) {
    min-width: fit-content !important;
  }
}
