@import 'src/scss-global/constants/media';

$active-tab-color: var(--wt-color-primary-light-theme);
$tab-color: var(--wt-color-dark-40);
$used-title-color: var(--wt-color-dark-70);

.tab {
  position: relative;

  &:not(:first-of-type) {
    margin-left: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $tab-color;
  }
}

.sup {
  vertical-align: super;
  font-size: smaller;
}

.usedTitle {
  color: $used-title-color;
}
