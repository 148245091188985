.boldText {
  font-weight: bold;
}

.subtitle {
  color: var(--wt-color-dark-70);
}

.point {
  margin: 0 !important;
  color: var(--wt-color-dark-40);
}

.numberCell {
  text-align: right;
}

.recession {
  color: var(--wt-color-success);
}

.increase {
  color: var(--wt-color-error);
}

.row {
  padding: 4px;
  transition: background-color ease-in .1s;
  cursor: pointer;

  &:hover {
    background-color: var(--wt-color-primary-light-theme-20);
  }
}

.totalRow {
  cursor: default;
}

.percentCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    margin-left: 6px;
  }
}

.zeroPercentCell {
  margin-right: 22px;
  display: block;
}

.tablePadding {
  & td {
    padding: 12px 0 !important;
  }
}

.errorMessage {
  color: var(--wt-color-error);
}
