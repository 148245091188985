$animation-linear-gradient: var(--wt-color-dark-5);

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(loading) {
  0% {
    right: 0;
  }
  100% {
    right: -100%;
  }
}

@mixin animation($param) {
  -webkit-animation: #{$param};
  animation: #{$param};
}

.skeletonAnimation {
  height: 24px;
  position: relative;
  overflow: hidden;

  &:before {
    background: linear-gradient(90deg, $animation-linear-gradient 0%, transparent 25%, $animation-linear-gradient 50%, transparent 75%, $animation-linear-gradient 100%);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 200%;
    height: 100%;
    @include animation('loading 4.5s infinite linear');
  }
}
