$border-color: var(--wt-color-dark-20);
$close-icon-color: var(--wt-color-dark-40);

.popup {
  & > div:first-of-type {
    padding: 12px 32px;
    & > button:last-of-type {
      display: none;
    }
  }

  & > div:nth-of-type(2) {
    padding: 0 32px 0 32px;
  }

  & > div:last-of-type {
    margin: -1px 0;
    padding: 24px 32px 24px 32px;
  }
}

.popupSmall {
  min-width: 650px !important;
  max-width: 650px !important;
}

.popupCreate {
  & > div:last-of-type {
    margin: -1px 0;
    padding: 48px 0 24px 32px;
  }
}

.popupHeaderUnderlined {
  & > div:first-of-type {
    border-bottom: 1px solid $border-color;
  }
}

.link {
  padding-left: 0 !important;
  margin-left: 16px;
}

.dateDot {
  padding: 0 4pt;
}

.red {
  color: var(--wt-color-error);
}

.closeButton {
  fill: $close-icon-color;
}
